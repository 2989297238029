<template>
  <div>
    <div class="lg:hidden">
      <AccountWallet
        v-if="showAccountWallet"
        @back="showAccountWallet = false"
        :accountWallet="data.accountWalletBalance"
      />
      <div class="px-6 py-2 flex flex-col text-text-primary lg:mt-32" v-else>
        <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto font-semibold">Wallet</h2>
        </header>
        <BPageLoader v-if="isLoading" class="flex-grow" />
        <main v-else>
          <div
            class="block rounded bg-bg-alternate-3 p-4 sm:py-6 my-4 text-center"
          >
            <h5 class="font-medium text-xs text-text-alternate-4">
              Combined Wallet Value
            </h5>
            <p class="font-bold text-2xl pt-2">
              ₦ {{ data.combinedWalletValue.toLocaleString() }}
            </p>
          </div>
          <hr class="my-4 text-text-alternate-8" />
          <div
            class="
              block
              rounded
              bg-bg-alternate-9
              p-4
              sm:py-6
              my-4
              text-center
              cursor-pointer
            "
            @click="showAccountWallet = true"
          >
            <h5 class="font-medium text-xs text-text-alternate-13">
              Account Wallet
            </h5>
            <p class="font-bold text-lg pt-2">
              ₦ {{ data.accountWalletBalance.toLocaleString() }}
            </p>
          </div>
          <hr class="my-4 text-text-alternate-8" />
          <div class="py-2">
            <h4 class="uppercase text-xs font-medium">My fundraiser wallets</h4>
            <div v-if="data.fundraiserWallets.length">
              <router-link
                v-for="{
                  id,
                  title,
                  wallet,
                  nextPayoutDate,
                } in data.fundraiserWallets"
                :key="id"
                :to="{ name: 'AccountFundraiser', params: { id } }"
                class="
                  block
                  rounded
                  p-4
                  sm:py-6
                  text-text-primary
                  my-4
                  border border-border-primary
                "
              >
                <h4
                  class="
                    font-semibold
                    text-sm
                    flex
                    items-center
                    justify-between
                  "
                >
                  <span class="truncate">{{ title }}</span>
                  <ChevronDownIcon
                    class="h-5 fill-current transform -rotate-90 ml-6"
                  />
                </h4>
                <div class="text-xs flex justify-between mt-4 items-baseline">
                  <p class="font-bold text-lg">
                    ₦ {{ wallet?.balance?.toLocaleString() }}
                  </p>
                  <p class="font-semibold text-2xs">
                    <span class="text-text-alternate-3 font-medium mr-1"
                      >Payout:</span
                    >
                    <span>
                      {{ dayjs(nextPayoutDate).format('D MMM') }}
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
            <div
              v-else
              class="
                flex flex-grow flex-col
                justify-center
                items-center
                pt-6
                pb-5
              "
            >
              <span
                class="
                  h-24
                  w-24
                  rounded-full
                  bg-bg-alternate-3
                  flex
                  items-center
                  justify-center
                  my-6
                "
              >
                <Fundraiser2Icon
                  class="h-8 stroke-2 stroke-current text-text-primary"
                />
              </span>
              <h4 class="my-4 text-lg font-semibold">No Fundraiser</h4>
              <p class="text-xs font-medium text-center">
                You have not created any fundraisers, please tap the button
                below to start
              </p>
            </div>
            <router-link
              class="
                font-bold
                text-sm
                w-full
                mt-2
                py-4
                text-center text-btn-text-primary
                bg-btn-bg-primary
                inline-block
                rounded
              "
              :to="{ name: 'CreateProject' }"
              >Create Fundraiser</router-link
            >
          </div>
        </main>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4 ">
      <div class="b-card mt-24 pb-12 px-26">
        <AccountWallet class="mt-8"
          v-if="showAccountWallet"
          @back="showAccountWallet = false"
          :accountWallet="data.accountWalletBalance"
        />
        <div class="px-6 py-2 flex flex-col text-text-primary pt-8" v-else>
          <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto font-semibold">Wallet</h2>
          </header>
          <BPageLoader v-if="isLoading" class="flex-grow" />
          <main v-else>
            <div
              class="
                block
                rounded
                bg-bg-alternate-3
                p-4
                sm:py-6
                my-4
                text-center
              "
            >
              <h5 class="font-medium text-xs text-text-alternate-4">
                Combined Wallet Value
              </h5>
              <p class="font-bold text-2xl pt-2">
                ₦ {{ data.combinedWalletValue.toLocaleString() }}
              </p>
            </div>
            <hr class="my-4 text-text-alternate-8" />
            <div
              class="
                block
                rounded
                bg-bg-alternate-9
                p-4
                sm:py-6
                my-4
                text-center
                cursor-pointer
              "
              @click="showAccountWallet = true"
            >
              <h5 class="font-medium text-xs text-text-alternate-13">
                Account Wallet
              </h5>
              <p class="font-bold text-lg pt-2">
                ₦ {{ data.accountWalletBalance.toLocaleString() }}
              </p>
            </div>
            <hr class="my-4 text-text-alternate-8" />
            <div class="py-2">
              <h4 class="uppercase text-xs font-medium">
                My fundraiser wallets
              </h4>
              <div v-if="data.fundraiserWallets.length">
                <router-link
                  v-for="{
                    id,
                    title,
                    wallet,
                    nextPayoutDate,
                  } in data.fundraiserWallets"
                  :key="id"
                  :to="{ name: 'AccountFundraiser', params: { id } }"
                  class="
                    block
                    rounded
                    p-4
                    sm:py-6
                    text-text-primary
                    my-4
                    border border-border-primary
                  "
                >
                  <h4
                    class="
                      font-semibold
                      text-sm
                      flex
                      items-center
                      justify-between
                    "
                  >
                    <span class="truncate">{{ title }}</span>
                    <ChevronDownIcon
                      class="h-5 fill-current transform -rotate-90 ml-6"
                    />
                  </h4>
                  <div class="text-xs flex justify-between mt-4 items-baseline">
                    <p class="font-bold text-lg">
                      ₦ {{ wallet?.balance?.toLocaleString() }}
                    </p>
                    <p class="font-semibold text-2xs">
                      <span class="text-text-alternate-3 font-medium mr-1"
                        >Payout:</span
                      >
                      <span>
                        {{ dayjs(nextPayoutDate).format('D MMM') }}
                      </span>
                    </p>
                  </div>
                </router-link>
              </div>
              <div
                v-else
                class="
                  flex flex-grow flex-col
                  justify-center
                  items-center
                  pt-6
                  pb-5
                "
              >
                <span
                  class="
                    h-24
                    w-24
                    rounded-full
                    bg-bg-alternate-3
                    flex
                    items-center
                    justify-center
                    my-6
                  "
                >
                  <Fundraiser2Icon
                    class="h-8 stroke-2 stroke-current text-text-primary"
                  />
                </span>
                <h4 class="my-4 text-lg font-semibold">No Fundraiser</h4>
                <p class="text-xs font-medium text-center">
                  You have not created any fundraisers, please tap the button
                  below to start
                </p>
              </div>
              <router-link
                class="
                  font-bold
                  text-sm
                  w-full
                  mt-2
                  py-4
                  text-center text-btn-text-primary
                  bg-btn-bg-primary
                  inline-block
                  rounded
                "
                :to="{ name: 'CreateProject' }"
                >Create Fundraiser</router-link
              >
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, ref } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getUserAccountWallet } from '@/services/api';
import { useStore } from 'vuex';
import randomColor from 'randomcolor';
import AccountWallet from './AccountWallet';
import dayjs from 'dayjs';

export default {
  name: 'Wallet',

  components: { AccountWallet },

  setup() {
    const store = useStore();
    const showAccountWallet = ref(false);
    const authUser = store.state.auth.authData;
    const [response, fetchUserWallet] = useApi(getUserAccountWallet);
    fetchUserWallet(authUser.id);

    return { ...toRefs(response), randomColor, showAccountWallet, dayjs };
  },
};
</script>
