<template>
  <div>
    <div class="px-6 py-2 flex flex-col text-text-primary">
      <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
        <button class="focus:outline-none" @click="$emit('back')">
          <LeftArrowIcon class="h-6 fill-current" />
        </button>
        <h2 class="mx-auto font-semibold">Account Wallet</h2>
      </header>
      <main>
        <div class="block rounded bg-bg-alternate-9 p-4 sm:py-6 my-4 text-center">
          <h5 class="font-medium text-xs text-text-alternate-13">Account Wallet</h5>
          <p class="font-bold text-2xl pt-2">₦ {{ accountWallet.toLocaleString() }}</p>
        </div>
        <button
          class="
            font-bold
            text-sm text-text-primary
            focus:outline-none
            rounded
            border
            py-2.5
            w-full
            mb-2
          "
          @click="showModal = true"
        >
          Top Up
        </button>
        <div class="text-text-alternate-1">
          <p class="flex justify-between font-semibold my-4">
            <span class="text-lg">Transaction History</span>
            <!-- <button class="focus:outline-none">
              <DiscoverIcon class="h-5 stroke-2 stroke-current" style="stroke:#7E7B7B" />
            </button> -->
          </p>
          <BPageLoader v-if="isLoading" class="flex-grow" />
          <ul v-else>
            <li
              v-for="history in data"
              :key="history.id"
              class="border-t border-border-primary py-2"
            >
              <p class="flex justify-between text-sm py-1">
                <span class="font-semibold capitalize">
                  {{ history.transactionType.toLowerCase() }}
                </span>
                <span
                  :class="[
                    'font-bold',
                    history.transactionType === 'TOP UP'
                      ? 'text-text-alternate-12'
                      : 'text-text-alternate-14',
                  ]"
                >
                  ₦{{ history.amount }}
                </span>
              </p>
              <p class="flex justify-between text-2xs text-text-alternate-7 font-medium py-1">
                <span class="text-2xs mr-2">
                  {{ dayjs(history.createdAt).format('DD MMM YYYY') }}
                </span>
                <span class="text-2xs">
                  {{ dayjs(history.createdAt).format('HH:mm:ss') }}
                </span>
                <span class="ml-auto capitalize">
                  via {{ history.paymentMethod.toLowerCase() }}
                </span>
              </p>
            </li>
          </ul>
        </div>
      </main>
    </div>
    <BModal :isOpen="showModal" @close="handleClose">
      <ul class="lg:px-26 lg:pt-8" v-if="stage === 1">
        <li class="text-xs font-semibold text-text-alternate-1">Top up with bank transfer</li>
        <li class="mt-2 mb-4">
          <div
            class="
              rounded
              bg-bg-alternate-8
              my-2
              flex flex-col
              justify-center
              items-center
              py-4
              text-text-secondary
              font-semibold
            "
          >
            <h3 class="uppercase text-text-alternate-9 text-xs">
              {{ account.bankName }}
            </h3>
            <p class="text-lg my-2 flex">
              <span class="mr-2">{{ account.accountNumber }}</span>
              <button @click="handleCopy(account.accountNumber)">
                <CopyIcon class="h-4 stroke-0 stroke-current fill-current" />
              </button>
            </p>
            <p class="text-xs">{{ account.accountName }}</p>
          </div>
        </li>
        <li class="my-4">
          <button
            class="border border-btn-border-alternate-1 btn-shadow w-full rounded py-3.5 sm:py-4"
            @click="stage = 2"
          >
            <div class="flex justify-center gap-3 py-px">
              <MasterCardIcon class="h-5" />
              <VisaIcon class="h-5" />
              <VerveIcon class="h-5" />
            </div>
            <p class="text-xs font-semibold mt-2 text-text-alternate-1">Top Up with bank card</p>
          </button>
        </li>
      </ul>

      <template v-else-if="stage === 2">
        <div class="flex lg:px-26 lg:pt-8 flex-col mt-2 text-text-primary font-semibold text-sm mb-24">
          <label>Amount (Naira)</label>
          <input
            class="
              border
              rounded
              border-border-primary
              mt-2
              mb-1
              p-3.75
              text-sm
              font-medium
              focus:outline-none
              input-shadow
            "
            type="number"
            v-model.number="amount"
            pattern="\d*"
            inputmode="decimal"
            min="0"
          />
        </div>
        <div class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 absolute left-0 w-full bottom-13">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary
              px-4
              py-3.5
              xs:py-4
              w-full
              rounded
              text-center text-sm
              xs:text-base
              font-bold
            "
            :disabled="!amount || topupResponse.isLoading"
            @click="processToPayment"
          >
            <BButtonLoader class="mx-auto h-4 w-4" v-if="topupResponse.isLoading" />
            <span v-else>Continue</span>
          </button>
        </div>
      </template>
    </BModal>
  </div>
</template>

<script>
import BModal from '@/components/ui/BModal';
import { ref, toRefs, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useApi } from '@/cmp-functions/useApi';
import { getWalletHistory, topupWallet } from '@/services/api';
import dayjs from 'dayjs';
import { useUtils } from '@/cmp-functions/useUtils';

export default {
  name: 'AccountWallet',

  components: { BModal },

  props: ['accountWallet'],

  emit: ['back'],

  setup() {
    const showModal = ref(false);
    const amount = ref('');
    const stage = ref(1);
    const store = useStore();
    const authUser = store.state.auth.userDetails;
    const account = computed(() => store.state.auth.userWallet.userreservedaccount);
    const [response, fetchWalletHistory] = useApi(getWalletHistory);
    const [topupResponse, toptupMyWallet] = useApi(topupWallet);
    const { handleCopy } = useUtils();

    const handleClose = () => {
      if (topupResponse.isLoading) return;
      showModal.value = false;
      stage.value = 1;
    };

    fetchWalletHistory(authUser.userid);

    const processToPayment = () => {
      const payload = {
        userId: authUser.userid,
        amount: amount.value,
      };

      toptupMyWallet(payload);
    };

    watch(topupResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        window.location.href = data.link;
      }
    });

    return {
      ...toRefs(response),
      topupResponse,
      showModal,
      processToPayment,
      amount,
      stage,
      dayjs,
      account,
      handleCopy,
      handleClose,
    };
  },
};
</script>
